<template>
  <div class="tw-payment-details">
    <div class="tw-payment-details__title text-center font-bold leading-4">
      {{ translate("generate.paymentSuccess.paymentDetails.yourPaymentDetails", locale) }}
    </div>
    <table class="tw-payment-details__table w-full">
      <tbody>
        <tr>
          <td>{{ translate("generate.paymentSuccess.paymentDetails.product", locale) }}</td>
          <td class="tw-payment-details__table-info-col">
            {{ getDetailInfo('product') }}
          </td>
        </tr>

        <tr>
          <template v-if="[PackagePaymentType.CONSUMABLE, PackagePaymentType.EPIN].includes(getDetailInfo('packageType'))">
            <td>{{ translate("generate.paymentSuccess.paymentDetails.paidOn", locale) }}</td>
            <td class="tw-payment-details__table-info-col">
              {{ getDetailInfo('paidOn') }}
            </td>
          </template>
          <template v-else>
            <td>{{ translate("generate.paymentSuccess.paymentDetails.expiresOn", locale) }}</td>
            <td class="tw-payment-details__table-info-col">
              {{ getDetailInfo('expiresOn') }}
            </td>
          </template>
        </tr>
        <tr>
          <td>{{ translate("generate.paymentSuccess.paymentDetails.paymentMethod", locale) }}</td>
          <td class="tw-payment-details__table-info-col">
            {{ getDetailInfo('paymentMethod') }}
          </td>
        </tr>
        <tr>
          <td>{{ translate("generate.paymentSuccess.paymentDetails.paymentAmount", locale) }}</td>
          <td class="tw-payment-details__table-info-col">
            {{ getDetailInfo('paymentAmount') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import type { PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { PackagePaymentType } from '@shared/elements/common/payment/types';
import { hexToRgba } from '@shared/utils/helpers';
import { useTranslate } from '@shared/composable/useTranslate';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['payment-result']>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { translate } = useTranslate();

const detailInfos = computed(() => props.isEditorMode ? {} : props.pageData?.paymentDetails || {});

function getDetailInfo(key: string) {
  return detailInfos.value?.[key] || '-';
}

const paymentDetailsStyle = computed(() => {
  const cardOptions = props.config.cards || {};
  return {
    rowBorderColor: hexToRgba(cardOptions.border?.color || '', 30),
    textColor: props.pageOptions?.colors?.text?.[0],
    fontFamily: props.pageOptions?.fontFamily
  }
})

</script>

<style lang="postcss" scoped>
.tw-payment-details {
  --paymentDetails-rowBorderColor: v-bind(paymentDetailsStyle.rowBorderColor);
  --paymentDetails-textColor: v-bind(paymentDetailsStyle.textColor);
  --paymentDetails-fontFamily: v-bind(paymentDetailsStyle.fontFamily);

  @apply w-full;
  color: var(--paymentDetails-textColor, #0D0626);
  font-family: var(--paymentDetails-fontFamily);
  word-break: break-word;

  &__title {
    @apply text-16 leading-5;
    word-break: break-word;
  }
  &__table {
    @apply text-12 font-normal;
    td {
      @apply py-12 border-b;
      border-color: var(--paymentDetails-rowBorderColor);
    }
    &-info-col {
      @apply text-right text-14 font-medium pl-4;
    }
  }
}
</style>
